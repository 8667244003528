<template>
  <div>
    <div v-if="loading" class="app-loading-screen">
      <PLoader></PLoader>
    </div>
    <router-view v-else :account="account"></router-view>
    <PToast></PToast>
  </div>
</template>

<script>
  import PLoader from "./components/loader/PLoader";
  import PToast from "./components/PToast";

  import {GET_ACCOUNT} from "./queries.js";

  const authRoutes = [
    'login',
    'signup',
    'forgot-password',
    'reset-password'
  ];

  export default {
    name: "App",
    components: {PToast, PLoader},
    data() {
      return {
        account: null,
      }
    },
    computed: {
      loading() {
        return this.$apollo.queries.account.loading;
      }
    },
    apollo: {
      account: {
        query: GET_ACCOUNT,
        async result ({ loading }) {
          if (!loading) {
            await this.afterLoad();
          }
        }
      }
    },
    methods: {
      async afterLoad() {
        if (this.account == null) {
          if (authRoutes.indexOf(this.$route.name) === -1) {
            await this.$router.push({name: 'login'});
          }
        } else {
          if (authRoutes.indexOf(this.$route.name) !== -1) {
            await this.$router.push({name: 'dashboard'});
          }
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "./scss/variables";

  .app-loading-screen {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<template>
  <span class="loader" title="Loading..." aria-label="Loading...">
    <img src="./PLoader.png" draggable="false" alt="">
  </span>
</template>

<script>
  export default {
    name: "PLoader"
  }
</script>

<style lang="scss">
  .loader {
    overflow: hidden;
    display: inline-block;
    width: 48px;
    height: 48px;

    img {
      width: 48px;
      height: 48px;
      animation-name: loader-animation;
      animation-duration: 0.6s;
      animation-delay: 0s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }

  @keyframes loader-animation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>

const state = {
  loadingUser: true,
  currentUser: null
};

const mutations = {
  setCurrentUser (state, user) {
    state.currentUser = user;
    state.loadingUser = false;
  }
};

const actions = {};

export default {
  state,
  mutations,
  actions
};

<template>
  <button class="button" :type="type" :class="classes" v-on="$listeners">
    <span><slot>Button</slot></span>
  </button>
</template>

<script>
  export default {
    name: "PButton",
    components: {},
    props: {
      type: {
        type: String,
        default: 'button'
      },
      fullWidth: {
        type: Boolean,
        required: false,
        default: false
      },
      destructive: {
        type: Boolean,
        required: false,
        default: false
      },
      loading: {
        type: Boolean,
        required: false,
        default: false
      }
    },

    computed: {
      classes() {
        return {
          'is-destructive': this.destructive,
          'button-full-width': this.fullWidth,
          'is-loading': this.loading
        }
      }
    }
  }
</script>

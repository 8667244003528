<template>
  <input v-if="multiline <= 1" class="text-field" :value="value" v-on:input="handleInput" v-bind="$attrs">
  <textarea v-else class="textarea" :rows="multiline" cols="80" v-on:input="handleInput" v-bind="$attrs"></textarea>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      value: {
        type: String
      },
      multiline: {
        type: Number,
        default: 1
      }
    },
    methods: {
      handleInput (event) {
        this.$emit("input", event.target.value);
      }
    }
  }
</script>

<template>
  <p class="form-error">
    <slot></slot>
  </p>
</template>

<script>
  export default {
    name: "PFormError",
  }
</script>

<style lang="scss">
  .form-error {
    text-align: center;
    margin: 16px 0;
    border-radius: 4px;
    background: #fdf6f5;
    color: rgba(#000, 0.7);
    border: 1px solid #e7c0bc;
    padding: 12px;
  }
</style>

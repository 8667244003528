import VueApollo from "vue-apollo";
import {HttpLink} from "apollo-link-http";
import {ApolloClient} from "apollo-client";
import {ApolloLink, concat} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";

const cache = new InMemoryCache();

// HTTP connection to the API
const httpLink = new HttpLink({
  uri: process.env.VUE_APP_API_BASE + '/private/graphql',
  headers: {},
  credentials: 'include'
});

const authMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
  }
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

export {
  apolloClient,
  apolloProvider
};

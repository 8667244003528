<template>
  <div class="auth-nav">
    <router-link to="/" class="logo">
      <img :src="logo" alt="Profanitor">
    </router-link>

    <div class="cta">
      <slot name="cta">
      </slot>
    </div>
  </div>
</template>

<script>
  import darkLogo from "@/assets/logo-dark.svg";
  import lightLogo from "@/assets/logo-light.svg";

  export default {
    name: "AuthNav",
    props: {
      lightLogo: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      logo() {
        return this.lightLogo ? lightLogo : darkLogo;
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/scss/variables";

  .auth-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;

    .logo {
      padding: 8px 16px;
      line-height: 0;

      img {
        height: 24px;
      }
    }

    .cta {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .nav-text {
      display: none;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
      .nav-text {
        display: block;
        margin-right: 16px;
      }
    }
  }
</style>

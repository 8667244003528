<template>
  <PModal v-if="show" :title="title" @close="onCancel">

    <p>{{message}}</p>

    <template v-slot:actions>
      <PButton class="button-secondary" :destructive="destructive" @click="onCancel">Cancel</PButton>
      <PButton :destructive="destructive" @click="onConfirm">{{confirmationCTA}}</PButton>
    </template>
  </PModal>
</template>

<script>
  import PModal from "@/components/PModal";
  import PButton from "@/components/PButton";

  export default {
    name: "DeleteTextModerationRulesModal",
    components: {PButton, PModal},
    props: {
      title: {
        type: String,
        required: true
      },
      message: {
        type: String,
        required: true
      },
      destructive: {
        type: Boolean,
        default: false
      },
      confirmationCTA: {
        type: String,
        default: "Confirm"
      },
    },
    data() {
      return {
        show: true
      }
    },
    beforeMount() {
      this.$nextTick(() => {
        document.body.appendChild(this.$el);
      });
    },
    methods: {
      onCancel() {
        this.$emit('cancel');
        this.close();
      },
      onConfirm() {
        this.$emit('confirm');
        this.close();
      },
      close() {
        this.show = false;

        setTimeout(() => {
          this.$destroy();
          this.$el.parentNode.removeChild(this.$el);
        }, 200);
      }
    },
  }
</script>

import gql from "graphql-tag/src";

const CREATE_TEXT_MODERATION_RULE = gql`
  mutation CreateTextModerationRules($serverId: ID!, $input: CreateTextModerationRuleInput!) {
    addTextModerationRules(serverId: $serverId, input: $input) {
      id
      type
      word
    }
  }
`;

const DELETE_TEXT_MODERATION_RULES = gql`
  mutation DeleteTextModerationRules($ruleIds: [ID!]!) {
    deleteTextModerationRules(ruleIds: $ruleIds)
  }
`;

export {
  CREATE_TEXT_MODERATION_RULE,
  DELETE_TEXT_MODERATION_RULES
};

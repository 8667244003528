<template>
  <div class="toast">
    <transition-group name="toast-transition" tag="div">
      <p v-for="message in messages" :key="message.id" class="toast-item" :class="message.type">{{message.text}}</p>
    </transition-group>
  </div>
</template>

<script>
  import {EventBus} from "../event-bus";

  export default {
    name: "PToast",
    data() {
      return {
        messages: []
      }
    },
    mounted() {
      EventBus.$on('toast', (text, options) => {
        this.addMessage(text, options);
      });
    },
    methods: {
      addMessage(text, options) {
        const id = this.generateID();

        this.messages.unshift({
          id: id,
          text: text,
          ...options
        });

        window.setTimeout(() => {
          this.messages = this.messages.filter((message) => {
            return message.id !== id;
          });
        }, 3000)
      },
      generateID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
    },
  }
</script>

<style lang="scss">
  @import "@/scss/variables";

  .toast {
    z-index: 1000;
    position: fixed;
    text-align: center;
    left: 0;
    right: 0;
    bottom: 5%; 

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-item {
      color: #ffffff;
      display: inline-block;
      background: rgba(#000, 0.8);
      box-shadow: 0 0 12px rgba(#000, 0.2);
      padding: 12px 20px;
      border-radius: 6px;
      margin: 0 0 8px;

      &.success {
        // color: #000;
        background: lighten($brand-color, 5%);
        box-shadow: 0 0 12px rgba($brand-color, 0.3);
      }

      &.error {
        background: $alert-color;
        box-shadow: 0 0 12px rgba($alert-color, 0.3);
      }
    }

    .toast-transition-enter-active {
      transition: all 0.4s;
    }

    .toast-transition-leave-active {
      position: fixed;
    }

    .toast-transition-enter, .toast-transition-leave-to {
      opacity: 0;
      transform: translateY(10px);
    }

    .toast-transition-move {
      transition: transform 0.5s ease-out, opacity 0.2s;
    }
  }
</style>

<template>
  <div class="login-screen">
    <AuthNav>
      <template v-slot:cta>
        <span class="nav-text">New to Profanitor?</span>
        <router-link :to="{name: 'signup'}" class="button button-secondary is-small">Start for Free</router-link>
      </template>
    </AuthNav>

    <LoginForm/>
  </div>
</template>

<script>
  import AuthNav from "../AuthNav";
  import LoginForm from "./LoginForm";
  export default {
    name: "LoginScreen",
    components: {AuthNav, LoginForm},
  }
</script>

<style lang="scss">
  .login-screen {
    min-height: 100vh;
    padding: 16px;
    background-color: #ffffff;
    background-image: url(../../../assets/background-pattern-dark.svg);
    background-size: cover;
    box-sizing: border-box;
  }
</style>

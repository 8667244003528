<template>
  <PModal :title="title" tag="form" @submit.prevent="onSubmit" v-on="$listeners" :click-to-close="false">
    <PFormField :label="label">
      <PTextField v-model="words" required></PTextField>
    </PFormField>

    <template v-slot:actions>
      <PButton class="button-secondary" @click="onCancel">Cancel</PButton>
      <PButton type="submit">Add</PButton>
    </template>
  </PModal>
</template>

<script>
  import PModal from "@/components/PModal";
  import PTextField from "@/components/PTextField";
  import PButton from "@/components/PButton";
  import PFormField from "@/components/PFormField";
  import {CREATE_TEXT_MODERATION_RULE} from "@/dashboard/mutations";

  export default {
    name: "CreateModerationRuleModal",
    components: {PFormField, PButton, PTextField, PModal},
    inheritAttrs: false,
    props: {
      type: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        words: ''
      }
    },
    computed: {
      title() {
        switch (this.type) {
          case "WHITELIST":
            return "Add words to whitelist";
          case "BLACKLIST":
            return "Add words to blacklist";
        }
        return null;
      },
      label() {
        switch (this.type) {
          case "WHITELIST":
            return "Enter whitelist words, separated by comma";
          case "BLACKLIST":
            return "Enter blacklist words, separated by comma";
        }
        return null;
      }
    },
    beforeMount() {
      this.$nextTick(() => {
        document.body.appendChild(this.$el);
      });
    },
    methods: {
      onSubmit() {
        const input = {
          type: this.type,
          words: this.words.split(',')
        };

        this.$apollo.mutate({
          mutation: CREATE_TEXT_MODERATION_RULE,
          variables: {
            serverId: this.$route.params['server_id'],
            input: input
          },
          refetchQueries: [
            "GetTextModerationRules"
          ]
        }).then(() => {
          this.close();
        });
      },
      onCancel() {
        this.close();
      },
      close() {
        this.$emit("close");
      }
    },
  }
</script>

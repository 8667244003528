<template>
  <AuthFormContainer class="login-form">
    <template v-slot:title>
      <h1 class="heading-2">Log In to Profanitor</h1>
    </template>

    <template v-slot:content>
      <form @submit.prevent="handleSubmit">
        <PFormError v-if="loginError">{{loginError}}</PFormError>

        <div class="form-group">
          <label for="email">Email</label>
          <PTextField class="text-field-large" v-model="email" autofocus required id="email" type="email" />
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <PTextField class="text-field-large" v-model="password" required id="password" type="password" />
        </div>

        <div class="form-group">
          <p class="reset-password-link">
            <router-link to="/auth/forgot-password" class="reset-password-link">Forgot Password?</router-link>
          </p>
        </div>

        <PButton :loading="submitting" type="submit">Log In</PButton>
      </form>
    </template>

    <template v-slot:helper>
      <p class="body-large">New to Profanitor?
        <router-link to="/signup" class="signup-link">Get started for free</router-link>
      </p>
    </template>
  </AuthFormContainer>
</template>

<script>
  import PFormError from "@/components/PFormError";
  import PTextField from "@/components/PTextField";
  import PButton from "@/components/PButton";
  import AuthFormContainer from "../AuthFormContainer";
  import {GET_ACCOUNT} from "@/queries";

  export default {
    components: {
      PButton,
      PTextField,
      PFormError,
      AuthFormContainer
    },

    data() {
      return {
        submitting: false,
        email: '',
        password: '',
        loginError: null
      }
    },

    methods: {
      async handleSubmit() {
        this.loginError = null;

        this.submitting = true;

        try {
          const _ = await this.$api.login(this.email, this.password);

          await this.$apollo.query({
            query: GET_ACCOUNT,
            fetchPolicy: 'network-only'
          });
        } catch (e) {
          this.loginError = e.response.data.error;
        } finally {
          this.submitting = false;
        }
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .login-form {
    margin-top: 40px;

    .form-group {
      margin: 0 0 24px;
    }

    .form-group label {
      display: inline-block;
      margin: 0 0 6px;
      font-weight: 500;
    }

    .button {
      width: 100%;
    }

    .helper-text {
      margin: 0 0 24px;
    }

    .centered {
      text-align: center;
    }
  }

  .reset-password-link {
    text-align: right;
  }
</style>

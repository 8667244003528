import AuthRoutes from "./modules/auth";
import DashboardRoutes from "./modules/dashboard";
import ErrorRoutes from "./modules/error";


export default [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...ErrorRoutes
]

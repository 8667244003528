import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueApollo from "vue-apollo";
import VueAnalytics from "vue-analytics";

import App from "./App.vue";

import routes from "./routes/all";
import {EventBus} from "./event-bus";
import apiClient from "./api-client";
import {apolloProvider} from "./apollo";
import storeModules from "./store/index";
import CreateModerationRuleModal from "@/dashboard/modals/CreateModerationRuleModal";
import DeleteTextModerationRulesModal from "@/dashboard/modals/DeleteTextModerationRulesModal";

// Polyfills
import "focus-visible";

// CSS
import "./scss/_main.scss";

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueApollo);

Vue.prototype.$api = apiClient;

Vue.prototype.$profanitor = {

  /**
   * @param message {String}
   * @param options {ToastParameters}
   **/
  toast: function (message, options = {}) {
    EventBus.$emit('toast', message, options);
  },

  /**
   * @param options {PromptParameters}
   **/
  prompt: function (options) {
    const ModalComponent = Vue.extend(DeleteTextModerationRulesModal);

    const instance = new ModalComponent({
      propsData: {
        title: options.title,
        message: options.message,
        destructive: options.destructive,
        confirmationCTA: options.confirmationCTA
      }
    });

    instance.$mount();

    return new Promise((resolve) => {
      instance.$on('cancel', () => resolve(false));
      instance.$on('confirm', () => resolve(true));
    });
  },

  modal: function (component) {
    const ModalComponent = Vue.component(component.name, component);
    const instance = new ModalComponent();
    instance.$mount();
  }
};

Vue.component(DeleteTextModerationRulesModal.name, DeleteTextModerationRulesModal);
Vue.component(CreateModerationRuleModal.name, CreateModerationRuleModal);

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

const store = new Vuex.Store({
  modules: storeModules,
});

if (process.env.NODE_ENV === "production") {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
    router
  });
}

const app = new Vue({
  router,
  store,
  apolloProvider,
  el: '#app',
  render: h => h(App)
});

export default app;

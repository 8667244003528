// TODO: split

// Dashboard
const Dashboard       = () => import(/* webpackChunkName: "dashboard" */ '@/screens/servers/ServersScreen.vue');
const NewServerWizard = () => import(/* webpackChunkName: "dashboard" */ '@/screens/new-server/NewServerScreen.vue');
const DashboardScreen = () => import(/* webpackChunkName: "dashboard" */ '@/dashboard/DashboardScreen.vue');

// Settings
const SettingsScreen        = () => import(/* webpackChunkName: "account-settings" */ "@/screens/settings/SettingsScreen");
const AccountSettingsScreen = () => import(/* webpackChunkName: "account-settings" */ "@/screens/settings/AccountSettingsScreen");
const PlansAndBillingScreen = () => import(/* webpackChunkName: "account-settings" */ "@/screens/settings/PlansAndBillingScreen");
const PlansScreen           = () => import(/* webpackChunkName: "account-settings" */ "@/screens/plans/PlansScreen");
const ConfirmPlan           = () => import(/* webpackChunkName: "account-settings" */ "@/screens/plans/ConfirmPlan");

import ServerDetailsRoutes from "./_server-details";

export default [
  {
    path: '/dashboard',
    component: DashboardScreen,
    children: [
      ...ServerDetailsRoutes,
      {
        name: 'dashboard',
        path: '',
        component: Dashboard
      },
      {
        name: 'plans',
        path: '/plans',
        component: PlansScreen,
      },
      {
        name: 'confirm-plan',
        path: '/plans/:plan_id/confirm',
        component: ConfirmPlan,
      },
      {
        path: '/settings',
        component: SettingsScreen,
        children: [
          {
            path: '',
            redirect: 'account'
          },
          {
            path: 'account',
            name: 'account-settings',
            component: AccountSettingsScreen
          },
          {
            path: 'billing',
            name: 'billing',
            component: PlansAndBillingScreen
          }
        ]
      },
      {
        path: '/new-server',
        component: NewServerWizard
      },
    ]
  },
]

import gql from "graphql-tag";

const GET_ACCOUNT = gql`
  query GetAccount {
    account:me {
      id
      firstName
      lastName
      company
      email
      status
      trial {
        endsAt
        daysLeft
      }
    }
  }
`;

export {
  GET_ACCOUNT
};

<template>
  <transition name="modal-transition" appear>
    <div class="modal" @click.self="onClick">
      <component :is="tag" class="modal-wrapper" v-on="$listeners" role="alertdialog" aria-modal="true">

        <div class="modal-header">
          <h4 id="modal-label">{{title}}</h4>
          <PActionButton type="button" title="Close" class="modal-close-button" @click="close">
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" v-bind:role="'presentation'" v-bind:focusable="'false'"><path d="M22.293 8.293l1.414 1.414L17.414 16l6.293 6.293-1.414 1.414L16 17.414l-6.293 6.293-1.414-1.414L14.586 16 8.293 9.707l1.414-1.414L16 14.586l6.293-6.293z" fill-rule="evenodd"/></svg>
          </PActionButton>
        </div>

        <div id="modal-desc" class="modal-content">
          <slot></slot>
        </div>

        <div class="modal-actions">
          <slot name="actions"></slot>
        </div>
      </component>
    </div>
  </transition>
</template>

<script>
  import PActionButton from "@/components/PActionButton";
  export default {
    name: "PModal",
    components: {PActionButton},
    inheritAttrs: false,
    props: {
      tag: {
        type: String,
        default: 'div'
      },
      title: {
        type: String,
        required: true
      },
      clickToClose: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data () {
      return {
        visible: true
      }
    },
    methods: {
      close() {
        this.$emit('cancel');
        this.$emit('close');
      },
      onClick(event) {
        event.preventDefault();
        event.stopPropagation();

        if (this.clickToClose) {
          this.close();
        }
      }
    }
  }
</script>

<style lang="scss">
  .modal-transition-enter-active, .modal-transition-leave-active {
    transition: all .2s;
    .modal-wrapper {
      transition: all .2s;
      transform: scale3d(1, 1, 1);
    }
  }

  .modal-transition-enter, .modal-transition-leave-to {
    opacity: 0;
    .modal-wrapper {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  .modal {
    z-index: 1000;
    box-sizing: border-box;
    padding: 16px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(#000, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;

    &-wrapper {
      box-sizing: border-box;
      background: #fff;
      width: 100%;
      max-width: 534px;
      border-radius: 4px;
    }

    &-header {
      padding: 24px 16px 0 24px;
      //border-bottom: 1px solid #dddddd;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h4 {
        margin: 8px 0 0;
      }
    }

    &-close-button {
      svg {
        width: 27px;
        height: 27px;
        fill: rgba(#000, 0.6);
      }
    }

    &-content {
      padding: 24px 24px 32px;
      & > *:first-child, & form > *:first-child { margin-top: 0; }
      & > *:last-child, & form > *:last-child { margin-bottom: 0; }
    }

    &-actions {
      text-align: right;
      padding: 16px 24px;
      background-color: #F7F6F9;

      .button {
        min-width: 90px;
        padding: 10px 16px;
        font-size: 16px;
      }

      .button:not(:last-of-type) {
        margin: 0 12px;
      }

      .button-secondary {
        background-color: #ffffff;
      }
    }
  }
</style>

<template>
  <div class="form-field">
    <label class="form-field-label">
      <span>{{label}}</span>
      <slot></slot>
    </label>

    <div class="form-field-hint" v-if="hint">
      {{hint}}
    </div>
  </div>
</template>

<script>
  export default {
    name: "PFormField",
    props: {
      label: {
        type: String,
        default: ""
      },
      hint: {
        type: String,
        default: null
      },
    },
  }
</script>

<style lang="scss">
  .form-field {
    margin: 0 0 16px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-label {
      width: 100%;

      > span {
        color: rgba(#000, 0.6);
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        clear: right;
        margin: 0 0 4px;
      }
    }

    &-hint {
      font-size: 14px;
      margin: 4px 0 0 0;
      color: rgba(#000, 0.4);
    }
  }
</style>

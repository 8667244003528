// Auth
import LoginScreen  from '@/screens/auth/login/LoginScreen.vue';
import SignupScreen from '@/screens/auth/signup/SignupScreen';
import ForgotPasswordScreen from '@/screens/auth/password-reset/ForgotPasswordScreen.vue';
import ResetPasswordScreen from '@/screens/auth/password-reset/ResetPasswordScreen';


export default [
  {
    name: 'login',
    path: '/',
    component: LoginScreen
  },
  {
    name: 'signup',
    path: '/signup',
    component: SignupScreen
  },
  {
    name: 'forgot-password',
    path: '/auth/forgot-password',
    component: ForgotPasswordScreen
  },
  {
    name: 'reset-password',
    path: '/auth/reset-password/:token',
    component: ResetPasswordScreen
  },
]

<template>
  <AuthFormContainer class="login-form">
    <template v-slot:title>
      <h1 class="heading-2">Reset Password</h1>
    </template>

    <template v-slot:content>
      <template v-if="updated">
        <p class="body-large centered">
          Your password was successfully updated!
        </p>
        <p class="body-large centered">
          <router-link :to="{name: 'login'}">Log in with new password</router-link>
        </p>
      </template>
      <form v-else @submit.prevent="onSubmit">
        <PFormError v-if="error">{{ error }}</PFormError>

        <div class="form-group">
          <label>New password</label>
          <PTextField v-model="newPassword" class="text-field-large" autofocus required id="new-password" type="password" name="new-password"/>
        </div>
        <div class="form-group">
          <label>Confirm new password</label>
          <PTextField v-model="confirmPassword" class="text-field-large" required id="confirm-password" type="password" name="confirm-password"/>
        </div>
        
        <PButton type="submit" :loading="updating">
          Update Password
        </PButton>
      </form>
    </template>
  </AuthFormContainer>
</template>

<script>
  import AuthFormContainer from "../AuthFormContainer";
  import PTextField from "../../../components/PTextField";
  import PButton from "../../../components/PButton";
  import PFormError from "@/components/PFormError";

  export default {
    name: "ResetPasswordForm",
    components: {PFormError, PButton, PTextField, AuthFormContainer},
    data() {
      return {
        updated: false,
        updating: false,
        newPassword: '',
        confirmPassword: '',
        error: null
      }
    },
    methods: {
      async onSubmit() {

        this.error = null;

        if (this.newPassword.length < 8) {
          this.error = "Passwords must be at least 8 characters.";
          return;
        }
        if (this.newPassword !== this.confirmPassword) {
          this.error = "The passwords don't match.";
          return;
        }
        
        try {
          this.updating = true;
          await this.$api.resetPassword(this.$route.params.token, this.newPassword);
          this.updated = true;
        } catch (e) {
          if (e.response !== undefined) {
            this.error = e.response.data.error;
          }
        } finally {
          this.updating = false;
        }
      }
    },
  }
</script>



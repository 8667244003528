<template>
  <div class="login-screen">
    <AuthNav/>
    <ForgotPasswordForm/>
  </div>
</template>
<script>
  import AuthNav from "../AuthNav";
  import ForgotPasswordForm from "./ForgotPasswordForm";
  export default {
    components: {ForgotPasswordForm, AuthNav}
  }
</script>

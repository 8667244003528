<template>
  <AuthFormContainer class="signup-form">
    <template v-slot:title>
      <div class="title">
        <h1 class="heading-2">Start for Free, Pick a Plan Later</h1>
        <p class="body-large">Everything you need to clean up toxic user-generated content. Accurate and fast results. No credit card required.</p>
      </div>
    </template>

    <template v-slot:content>
      <form @submit.prevent="onSubmit">
        <PFormError v-if="signUpError">{{signUpError}}</PFormError>

        <div class="form-group">
          <label for="email">Work email</label>
          <PTextField class="text-field-large" v-model="account.email" autofocus required id="email" type="email" />
        </div>

        <div class="form-group">
          <label for="name">Full name</label>
          <PTextField class="text-field-large" v-model="account.name" required id="name" type="text" />
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <PTextField class="text-field-large" v-model="account.password" minlength="8" required id="password" type="password" placeholder="Minimum 8 characters"/>
        </div>

        <p class="disclaimer">
          By clicking the button, you agree to Profanitor’s
          <a href="https://profanitor.com/legal/terms-of-service/" target="_blank">Terms of Service</a> and
          <a href="https://profanitor.com/legal/privacy-policy/" target="_blank">Privacy Policy</a>.
        </p>

        <PButton type="submit" :loading="submitting">
          Start Free Trial
          <span class="symbol">→</span>
        </PButton>
      </form>
    </template>
  </AuthFormContainer>
</template>

<script>
  import PTextField from "@/components/PTextField";
  import PButton from "@/components/PButton";
  import PFormError from "@/components/PFormError";
  import AuthFormContainer from "../AuthFormContainer";
  import {GET_ACCOUNT} from "@/queries";

  export default {
    name: "SignupForm",
    components: {PButton, PFormError, PTextField, AuthFormContainer},
    data() {
      return {
        account: {
          email: '',
          name: '',
          password: '',
        },
        signUpError: null,
        submitting: false,
      }
    },
    methods: {
      async onSubmit() {
        this.submitting = true;

        try {
          const _ = await this.$api.signUp(this.account.name, this.account.email, this.account.password);

          await this.$apollo.query({
            query: GET_ACCOUNT,
            fetchPolicy: 'network-only'
          });
        } catch (e) {
          if (e.response !== undefined) {
            this.signUpError = e.response.data.error;
          }
        } finally {
          this.submitting = false;
        }
      }
    },
  }
</script>

<style lang="scss">
  .signup-form {
    .title {
      color: #FFFFFF;

      > h1 {
        margin-bottom: 16px;
      }
    }

    .form-group {
      margin: 0 0 24px;
    }

    .form-group label {
      display: inline-block;
      margin: 0 0 6px;
      font-weight: 500;
    }

    .button {
      width: 100%;
      margin: 24px 0 0;
      transition: all 0.2s ease-out;

      .symbol {
        display: inline-flex;
        align-self: center;
        position: relative;
        left: 8px;
        transform: translateX(0);
        transition: transform 0.15s linear;
      }

      &:hover {
        transform: translateY(-2px);
        background-color: #00A370;
        box-shadow: 0 10px 8px -10px rgba(#000, 0.4);

        .symbol {
          transform: translateX(4px);
        }
      }

      &:active {
        background-color: #008158;
        transform: translateY(0);
        box-shadow: 0 8px 4px -8px rgba(#000, 0.4);
      }
    }
  }
</style>

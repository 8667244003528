import {merge} from "lodash";
import axios from "axios";
import humps from "humps";

const APIClient = function () {
  this.http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    timeout: 30 * 1000,
    credentials: 'same-origin',
    withCredentials: true,
    transformResponse: [
      ...axios.defaults.transformResponse,
      data => humps.camelizeKeys(data)
    ],
    transformRequest: [
      data => humps.decamelizeKeys(data),
      ...axios.defaults.transformRequest
    ]
  });
};

// -------------------------------------------------
//  Auth
// -------------------------------------------------

APIClient.prototype.login = function (email, password) {
  return this.http.post('/private/auth/login', {
    email: email,
    password: password
  });
};

APIClient.prototype.logout = async function () {
  const response = await this.http.post('/private/auth/logout');
  return response.data;
};

// -------------------------------------------------
//  Password Reset
// -------------------------------------------------

APIClient.prototype.createPasswordResetToken = function (email) {
  return this.http.post('/private/password-reset-tokens', {
    email: email
  });
};

APIClient.prototype.resetPassword = function (token, password) {
  return this.http.post('/private/reset-password', {
    token: token,
    password: password
  });
};


// -------------------------------------------------
//  Sign Up
// -------------------------------------------------

APIClient.prototype.signUp = async function (name, email, password) {
  const response = await this.http.post(`/private/signup`, {
    name,
    email,
    password
  });

  return response.data;
}

// -------------------------------------------------
//  Billing
// -------------------------------------------------

APIClient.prototype.getUsage = async function () {
  const response = await this.http.get('/private/usage');
  return response.data;
};

APIClient.prototype.getInvoices = async function () {
  const response = await this.http.get('/private/billing/invoices');
  return response.data;
};

APIClient.prototype.getCurrentUsage = async function () {
  const response = await this.http.get('/private/billing/current-usage');
  return response.data;
};

APIClient.prototype.updatePaymentMethod = async function (token) {
  const response = await this.http.put('/private/billing/payment-method', {
    token
  });

  return response.data;
};

// -------------------------------------------------
//  Console
// -------------------------------------------------

APIClient.prototype.moderateText = function (apiKey, text, options) {
  return this.http({
    method: 'post',
    url: '/text-moderation/check',
    data: merge(options, {text: text}),
    headers: {
      'x-api-key': apiKey
    }
  });
};

APIClient.prototype.imageModeration = function (apiKey, data) {
  return this.http({
    method: 'post',
    url: '/image-moderation/check',
    data: data,
    headers: {
      'x-api-key': apiKey
    }
  });
};

export default new APIClient();

export {
  APIClient
};

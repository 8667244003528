<template>
  <div class="login-screen">
    <AuthNav/>
    <ResetPasswordForm/>
  </div>
</template>

<script>
  import AuthNav from "../AuthNav";
  import ResetPasswordForm from "./ResetPasswordForm";
  export default {
    name: "ResetPasswordScreen",
    components: {ResetPasswordForm, AuthNav}
  }
</script>


<template>
  <div class="signup-screen">
    <AuthNav light-logo>
      <template v-slot:cta>
        <span class="nav-text">Already have an account?</span>
        <router-link :to="{name: 'login'}" class="button button-ghost is-small">Log In</router-link>
      </template>
    </AuthNav>

    <SignupForm/>
  </div>
</template>

<script>
  import AuthNav from "../AuthNav";
  import SignupForm from "./SignupForm";

  export default {
    name: "SignupScreen",
    components: {AuthNav, SignupForm}
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .signup-screen {
    background-color: $brand-color-dark50;
    background-image: url(../../../assets/background-pattern-light.svg);
    min-height: 100vh;
    padding: 16px;
    background-size: cover;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .auth-form-container {
      flex-grow: 1;
    }

    .nav-text {
      color: #fff;
    }
  }
</style>

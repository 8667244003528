import client from "../../api-client";

const state = () => ({
  paymentMethod: null,
  currentUsage: null
});

const mutations = {
  setPaymentMethod (state, paymentMethod) {
    state.paymentMethod = paymentMethod;
  },

  setCurrentUsage (state, currentUsage) {
    state.currentUsage = currentUsage;
  },
};

const actions = {
  loadCurrentUsage (context) {
    client.getUsage().then((currentUsage) => {
      context.commit('setCurrentUsage', currentUsage);
    });
  },
};

export default {
  state,
  mutations,
  actions
};

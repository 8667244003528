const _serverDetails = () => import(/* webpackChunkName: "server-details" */ '@/screens/server-details/ServerDetails.vue');

// Tabs
const APIConsole               = () => import(/* webpackChunkName: "server-details" */ '@/dashboard/APIConsole.vue');
const ProfanityFilterBlacklist = () => import(/* webpackChunkName: "server-details" */ '@/screens/server-details/ProfanityFilterBlacklist.vue');
const ProfanityFilterWhitelist = () => import(/* webpackChunkName: "server-details" */ '@/screens/server-details/ProfanityFilterWhitelist.vue');
const ProfanityFilterSettings  = () => import(/* webpackChunkName: "server-details" */ '@/dashboard/ServerSettings.vue');
const ProfanityFilterDashboard = () => import(/* webpackChunkName: "server-details" */ "@/dashboard/ServerDashboard");
const ImageModerationModelsTab = () => import(/* webpackChunkName: "server-details" */ "@/screens/server-details/tabs/models/ImageModerationModelsTab");

export default [
  {
    path: '/servers/:server_id',
    component: _serverDetails,
    children: [
      {
        name: 'server-details.dashboard',
        path: 'dashboard',
        component: ProfanityFilterDashboard
      },
      {
        name: 'server-details.blacklist',
        path: 'blacklist',
        component: ProfanityFilterBlacklist
      },
      {
        name: 'server-details.whitelist',
        path: 'whitelist',
        component: ProfanityFilterWhitelist
      },
      {
        name: 'server-details.models',
        path: 'models',
        component: ImageModerationModelsTab
      },
      {
        name: 'server-details.console',
        path: 'console',
        component: APIConsole
      },
      {
        name: 'server-details.settings',
        path: 'settings',
        component: ProfanityFilterSettings
      }
    ]
  }
]

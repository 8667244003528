<template>
  <AuthFormContainer class="login-form">
    <template v-slot:title>
      <h1 class="heading-2">Reset Password</h1>
    </template>

    <template v-slot:content>
      <form @submit.prevent="handleSubmit">
        <template v-if="submitted">
          <p class="body-large helper-text">
            An email containing instructions on how to reset your password has
            been sent to <strong>{{ email }}</strong>.
          </p>
          <p class="body-large">
            Didn't get the email? Check your spam folder or
            <a href="/auth/forgot-password">resend the email</a>.
          </p>
        </template>
        <template v-else>
          <PFormError v-if="error">{{ error }}</PFormError>

          <p class="body-large helper-text">Enter the email address associated with your account and we’ll send you instructions to reset your password.</p>

          <div class="form-group">
            <PTextField class="text-field-large" autofocus required type="email" v-model="email" placeholder="Enter your work email..." />
          </div>

          <PButton type="submit" :loading="creating">
            Email me the instructions
          </PButton>
        </template>
      </form>
    </template>

    <template v-slot:helper>
      <router-link to="/">Back to Login</router-link>
    </template>
  </AuthFormContainer>
</template>

<script>
  import PButton from "@/components/PButton";
  import PTextField from "@/components/PTextField";
  import PFormError from "@/components/PFormError";
  import AuthFormContainer from "../AuthFormContainer";

  export default {
    name: "ForgotPasswordForm",
    components: {PFormError, PButton, PTextField, AuthFormContainer},
    data () {
      return {
        error: null,
        creating: false,
        submitted: false,
        email: ''
      }
    },
    methods: {
      async handleSubmit() {        
        try {
          this.creating = true;

          await this.$api.createPasswordResetToken(this.email);
          this.submitted = true;
        } catch (e) {
          if (e.response !== undefined) {
            this.error = e.response.data.error;
          }
        } finally {
          this.creating = false;
        }
      } 
    }
  }
</script>

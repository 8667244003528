<template>
  <div class="auth-form-container">
    <div class="auth-screen-title">
      <slot name="title"></slot>
    </div>

    <div class="auth-form-container-box">
      <slot name="content"></slot>
    </div>

    <div class="auth-form-container-helper">
      <slot name="helper"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: "AuthFormContainer"
  }
</script>

<style lang="scss">
  .auth-form-container {
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .auth-screen-title {
      text-align: center;
      margin: 0 0 24px;
    }

    &-box {
      width: 100%;
      padding: 40px;
      margin: 0 auto 40px;
      background: #fff;
      border-radius: 6px;
      box-shadow: rgba(60, 66, 87, 0.12) 0 2px 14px 0, rgba(0, 0, 0, 0.12) 0 0 4px 0;
      box-sizing: border-box;
      flex-shrink: 0;
    }

    &-helper {
      text-align: center;
      font-size: 18px;
    }
  }
</style>
